import { useEffect, useState } from 'react';
import './App.css';
import { getToken } from './config/cookie';
import Login from './Pages/Login';
import Main from './Pages/Main';
import { StylesProvider, createGenerateClassName } from '@material-ui/core/styles';

function App() {

  const generateClassName = createGenerateClassName({
    // By enabling this option, if you have non-MUI elements (e.g. `<div />`)
    // using MUI classes (e.g. `.MuiButton`) they will lose styles.
    // Make sure to convert them to use `styled()` or `<Box />` first.
    disableGlobal: true,
    // Class names will receive this seed to avoid name collisions.
    seed: 'mui-jss',
  });

  const [logined, setLogined] = useState(false);

  useEffect(() => {

    if (getToken()) {
      setLogined(true);
    }
  }, [])

  return (
    <StylesProvider generateClassName={generateClassName}>
      {logined
        ?
        <Main setLogined={setLogined} />
        :
        <Login setLogined={setLogined} />
      }
    </StylesProvider>
  );
}

export default App;

import StyledTable from "../Components/StyledTable";
import StyledButton from "../Components/StyledButton";
import { useEffect, useState } from "react";
import withWidth from "@material-ui/core/withWidth";
import { fetchAppSwitch, fetchCreate, fetchData, updateData, deleteData } from "../config/api";
import { getTokenValue } from "../config/cookie";
import { Box, Snackbar } from "@material-ui/core";
import { Alert } from "@mui/lab";
import StyledModal from "../Components/StyledModal";
import GuideModal from "../Components/GuideModal";


export const heads = [
  { title: "Name", value: "name" },
  { title: "Identify", value: "identify" },
  { title: "Date", value: "date" },
  { title: "Updated", value: "update_date" },
  { title: "Description", value: "description" },
  { title: "App URL", value: "app_url" },
  { title: "Play URL", value: "play_url" },
  { title: "Play Status", value: "play_status" },
  { title: "", value: "actions" },
  { title: "", value: "edit" },
];

function Main({ width }) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [guideOpen, setGuideOpen] = useState(false);
  const [state, setState] = useState([]);
  const [snack, setSnack] = useState({
    open: false,
    type: "",
    text: "",
  });
  const [data, setData] = useState({
    name: "",
    identify: "",
    play_url: "",
    app_url: "",
    description: "",
  });

  const handleClose = () => {
    setSnack({ ...snack, open: false });
  };

  const handleOpen = (type, text) => {
    setSnack({
      open: true,
      type,
      text,
    });
  };

  const getData = async () => {
    setLoading(true);
    try {
      const res = await fetchData(getTokenValue());
      console.log(res);
      if (res.success) {
        setState([...res.data]);
      } else {
        handleOpen("error", res.message);
      }

      setLoading(false);
    } catch (err) {
      console.log(err);
      handleOpen("error", "Error");
      setLoading(false);
    }
  };

  const switchApp = async (id, turn) => {
    setLoading(true);
    try {
      const res = await fetchAppSwitch(getTokenValue(), { id });

      console.log(res);
      if (res.success) {
        getData();
        handleOpen("success", "Changed");
      } else {
        handleOpen("error", res.message);
      }

      setLoading(false);
    } catch (err) {
      console.log(err);
      handleOpen("error", "Error");
      setLoading(false);
    }
  };

  const update = async (data) => {
    setLoading(true);
    try {
      const res = await updateData(getTokenValue(), data);

      console.log(res);
      if (res.success) {
        getData();
        handleOpen("success", "Changed");
      } else {
        handleOpen("error", res.message);
      }

      setLoading(false);
    } catch (err) {
      console.log(err);
      handleOpen("error", "Error");
      setLoading(false);
    }
  };

  const deleteApp = async (data) => {
    setLoading(true);
    try {
      const res = await deleteData(getTokenValue(), data);

      console.log(res);
      if (res.success) {
        getData();
        handleOpen("success", "Deleted");
      } else {
        handleOpen("error", res.message);
      }

      setLoading(false);
    } catch (err) {
      console.log(err);
      handleOpen("error", "Error");
      setLoading(false);
    }
  };

  const create = async () => {
    console.log(data);
    if (!data.name || !data.identify) return;
    setLoading(true);

    try {
      const res = await fetchCreate(getTokenValue(), data);
      console.log(res);

      if (res.success) {
        getData();
        setData({
          name: "",
          identify: "",
          play_url: "",
          app_url: "",
          description: "",
        });
        handleOpen("success", "Created");
      } else {
        handleOpen("error", res.message);
      }
      setOpen(false);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="App">
      <Snackbar
        open={snack.open}
        autoHideDuration={5000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Alert
          onClose={handleClose}
          variant="filled"
          severity={snack.type}
          sx={{ width: "100%" }}
        >
          {snack.text}
        </Alert>
      </Snackbar>

      <StyledModal
        setOpen={setOpen}
        open={open}
        callback={create}
        data={data}
        setData={setData}
        loading={loading}
      />

      <GuideModal
        open={guideOpen}
        setOpen={setGuideOpen}
      />

      <Box
        style={{
          display: "flex",
          flexDirection: width === "xs" ? "column" : "row",
          justifyContent: "flex-end",
        }}
      >
        <StyledButton
          type="contained"
          size="medium"
          loading={false}
          callback={() => setGuideOpen(true)}
          style={{ margin: width === "xs" ? "0 0 15px 0" : "0 15px 15px 0" }}
        >
          Guide
        </StyledButton>

        <StyledButton
          type="contained"
          size="medium"
          loading={loading}
          callback={() => setOpen(true)}
          style={{ margin: width === "xs" ? "0 0 15px 0" : "0 15px 15px 0" }}
        >
          Create
        </StyledButton>

        <StyledButton
          type="contained"
          size="medium"
          loading={loading}
          callback={getData}
          style={{ marginBottom: "15px" }}
        >
          Reload
        </StyledButton>
      </Box>

      <StyledTable
        data={state}
        searchBy={"name"}
        width={width}
        callback={switchApp}
        update={update}
        deleteData={deleteApp}
        heads={heads}
        loading={loading}
      />
    </div>
  );
}

export default withWidth()(Main);

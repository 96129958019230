import { Box, TextField, Typography } from "@mui/material";
import { useState } from "react";
import StyledButton from "../Components/StyledButton";
import { fetchLogin } from "../config/api";
import { setToken } from "../config/cookie";

function Login({ setLogined }) {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    email: "",
    password: "",
  });

  const log = async () => {
    console.log(state);
    if (!state.email || !state.password) return;
    setLoading(true);

    try {
      const res = await fetchLogin(state);
      console.log(res);

      if (res.success) {
        setToken(res.token);
        setLogined(true);
      }

      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <Box
      style={{
        position: "absolute",
        top: "0px",
        bottom: "0px",
        left: "0px",
        right: "0px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "50px 30px",
          backgroundColor: "#f0f0f0",
          borderRadius: "10px",
          width: "100%",
          maxWidth: "500px",
        }}
      >
        <Typography>Sign in</Typography>

        <TextField
          label="Email"
          variant="standard"
          type="email"
          onChange={(e) => setState({ ...state, email: e.target.value })}
          style={{ margin: "15px 0px" }}
        />

        <TextField
          label="Password"
          variant="standard"
          type="password"
          onChange={(e) => setState({ ...state, password: e.target.value })}
        />

        <StyledButton
          type="contained"
          size="medium"
          loading={loading}
          callback={log}
          style={{ marginTop: "20px" }}
        >
          Sign in
        </StyledButton>
      </Box>
    </Box>
  );
}

export default Login;

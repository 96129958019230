import { useState } from "react";
import { Box, Typography } from "@mui/material";
import StyledButton from "../Components/StyledButton";
import { Backdrop, Icon } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";

function DeleteModal ({ open, setOpen, id, deleteData }) {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    deleteData({ id });
    setLoading(false);
  }

  return (
    <Backdrop open={open} style={{ color: "#fff", zIndex: 9999 }}>
      <Box
        style={{
          position: "absolute",
          top: "0px",
          bottom: "0px",
          left: "0px",
          right: "0px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "50px 30px",
            backgroundColor: "#f0f0f0",
            borderRadius: "10px",
            width: "100%",
            maxWidth: "300px",
            position: "relative",
          }}
        >
          <Icon
            style={{
              display: "flex!important",
              width: "fit-content!important",
              height: "fit-content!important",
              cursor: "pointer",
              color: "red",
              position: "absolute",
              right: "10px",
              top: "20px",
            }}
            onClick={() => setOpen(false)}
          >
            <CloseIcon
              style={{
                width: "30px!important",
                height: "30px!important",
              }}
            />
          </Icon>

          <Typography style={{ color: "black", textAlign: "center" }}>
            Delete App?
          </Typography>

          <Box style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '10px',
          }}>
            <StyledButton
              type="contained"
              size="medium"
              loading={loading}
              callback={handleSubmit}
              style={{ marginTop: "20px", backgroundColor: "rgb(25, 118, 210)" }}
            >
              Delete
            </StyledButton>
            <StyledButton
              type="contained"
              size="medium"
              loading={false}
              callback={() => setOpen(false)}
              style={{ marginTop: "20px", backgroundColor: "rgb(25, 118, 210)" }}
            >
              Cancel
            </StyledButton>
          </Box>
        </Box>
      </Box>
    </Backdrop>
  );
}

export default DeleteModal;
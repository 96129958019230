import React, { useState, useEffect } from "react";
import { styled } from "@material-ui/core/styles";
import { Table as TableMui } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Icon, InputAdornment, OutlinedInput } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, TableFooter, TablePagination } from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import { useTheme } from "@material-ui/core/styles";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import StyledButton from "./StyledButton";
import EditButtons from "./EditButtons";

const StyledOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  margin: "10px 0 0 0",
  minWidth: "200px",
  "& fieldset": {
    borderColor: "#1976d2!important",
  },
  "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#1976d2",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#1976d2",
  },
  "&.MuiTableCell-root": {
    maxWidth: "100px",
    wordWrap: "break-word",
  },
}));

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }} style={{ minWidth: "200px" }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

function StyledTable({ width, data, heads, searchBy, callback, update, deleteData, loading }) {
  const [page, setPage] = useState(0);
  const [state, setState] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (data) {
      setState([...data]);
    }
  }, [data]);

  const handleSearch = () => (e) => {
    const by = e.target.value;
    setSearch(by);
    if (!by) {
      setState([...data]);
      return;
    }

    const arr = data.filter((item) => {
      let need = item[searchBy].toString().split("");
      need.length = by.length;
      need = need.join("");
      return need.toLowerCase() === by.toLowerCase();
    });

    setState([...arr]);
  };

  const HeadCells = heads.map((item, i) => {
    return (
      <TableCell
        key={i}
        align={i === 0 ? "left" : "right"}
        style={{
          backgroundColor: "#1976d2",
          borderBottom: "0px",
          maxWidth: "100px",
          wordWrap: "break-word !important",
        }}
      >
        {item.title}
      </TableCell>
    );
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const Actions = ({ status, id }) => (
    <>
      <StyledButton
        type="contained"
        color="primary"
        size="small"
        loading={loading}
        callback={() => callback(id, true)}
        disabled={+status}
        style={{
          margin: width !== "xs" ? "0 7px 0 0" : "0 0 7px 0",
        }}
      >
        On
      </StyledButton>
      <StyledButton
        type="contained"
        color="error"
        size="small"
        loading={loading}
        disabled={!+status}
        callback={() => callback(id, false)}
      >
        Off
      </StyledButton>
    </>
  );

  const rows = state?.length
    ? state?.map((row, i) => {
        const customRows = heads.map((item, i) => (
          <TableCell 
            key={`${i}2`} 
            align={i === 0 ? "left" : "right"} 
            style={{
              maxWidth: "150px",
              wordWrap: "break-word",
            }}
          >
            {item.value === "actions" ? (
              <Actions status={row.status} id={row.id} />
            ) : 
              item.value === "edit" ? (
                <EditButtons width={width} data={row} update={update} deleteData={deleteData} />
              ) : (
                row[item.value]
              )}
          </TableCell>
        ));

        return (
          <TableRow
            key={i}
            style={{
              cursor: width === "sm" || width === "xs" ? "pointer" : "default",
            }}
          >
            {customRows}
          </TableRow>
        );
      })
    : [];

  return (
    <>
      <Box
        sx={{ width: "100%", typography: "body1", flexWrap: "wrap" }}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-end",
          height: "fit-content",
          marginBottom: "10px",
        }}
      >
        <StyledOutlinedInput
          type="text"
          style={{ width: width === "xs" ? "100%" : null }}
          value={search}
          onChange={handleSearch()}
          placeholder={searchBy}
          startAdornment={
            <InputAdornment position="start">
              <div
                style={{
                  display: "flex",
                  alignContent: "center",
                  justifyContent: "center",
                  fontSize: "16px",
                  color: "#BDC0C6",
                }}
              >
                <Icon>
                  <SearchIcon />
                </Icon>
              </div>
            </InputAdornment>
          }
        />
      </Box>

      {state.length ? (
        <TableContainer
          component={Paper}
          style={{
            boxShadow: "none",
            padding: "0px",
          }}
        >
          <TableMui sx={{ minWidth: 460 }} aria-label="customized table">
            <TableHead>
              <TableRow style={{ backgroundColor: "black" }}>
                {HeadCells}
              </TableRow>
            </TableHead>

            <TableBody>{rows.slice(page * 10, page * 10 + 10)}</TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={heads.length}
                  count={rows.length ? rows.length : 0}
                  rowsPerPage={10}
                  rowsPerPageOptions={-1}
                  page={page}
                  onPageChange={handleChangePage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </TableMui>
        </TableContainer>
      ) : (
        <div
          style={{
            height: "100px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "20px",
            fontSize: "0.750rem!important",
            color: "black",
          }}
        >
          Anything here
        </div>
      )}
    </>
  );
}

export default StyledTable;

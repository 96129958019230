import { useState } from "react";
import { Box, Typography } from "@mui/material";
import StyledButton from "../Components/StyledButton";
import { Backdrop, Icon } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";

function GuideModal ({ open, setOpen }) {
  return (
    <Backdrop open={open} style={{ color: "#fff", zIndex: 9999 }}>
      <Box
        style={{
          position: "absolute",
          top: "0px",
          bottom: "0px",
          left: "0px",
          right: "0px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "50px 30px",
            backgroundColor: "#f0f0f0",
            borderRadius: "10px",
            width: "100%",
            maxWidth: "500px",
            position: "relative",
          }}
        >
          <Icon
            style={{
              display: "flex!important",
              width: "fit-content!important",
              height: "fit-content!important",
              cursor: "pointer",
              color: "red",
              position: "absolute",
              right: "10px",
              top: "20px",
            }}
            onClick={() => setOpen(false)}
          >
            <CloseIcon
              style={{
                width: "30px!important",
                height: "30px!important",
              }}
            />
          </Icon>

          <Typography mb={2} style={{ color: "black", textAlign: "center" }}>
            Guide
          </Typography>
          
          <Typography variant="body1" gutterBottom style={{ color: "black", textAlign: "left" }}>
            В head: <br />
            <code style={{backgroundColor: '#D6E4F0'}}>
              &lt;? function get_form() &#123;
    
              $url = "{window.location.origin}/api/app?identify=" . idn_to_utf8($_SERVER['SERVER_NAME']);
  
              $options = array(
                  'http' =&gt; array(
                      'method' =&gt; 'GET',
                      'header' =&gt; "Content-type: application/json\r\n"
                  )
              );
              $context = stream_context_create($options);
              $result = file_get_contents($url, false, $context);
              $response = json_decode($result, true);

              return $response['success'] ? $response['data']['status'] == '1' : 1;
              &#125;
              try &#123;
              $form = get_form();
              &#125;
              catch(Exception $e)
              &#123;
                  $form = true
                  ;&#125;
              ?&gt;
            </code>
          </Typography>
          <Typography variant="body1" gutterBottom style={{ color: "black", textAlign: "left" }}>
            Для больших текстов:<br />
            <code style={{backgroundColor: '#D6E4F0'}}>
              &lt;? if($form) &#123; print("Заполните форму. На указанный номер телефона придет СМС со ссылкой на обучение."); &#125; else &#123; print("Спасибо, опрос пройден"); &#125;?&gt;
            </code>
          </Typography>
          <Typography variant="body1" gutterBottom style={{ color: "black", textAlign: "left" }}>
            Для маленьких: <br />
            <code style={{backgroundColor: '#D6E4F0'}}>
              &lt;? form == true ? print('Тинькофф') : print('Не тинькофф'); ?&gt;
            </code>
          </Typography>
        </Box>
      </Box>
    </Backdrop>
  );
}

export default GuideModal;
const url = "https://apps.catoftree.com/api";


export const fetchLogin = async (data) => {

    const result = await fetch(`${url}/login`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*"
        }
    })
        .then(res => {

            return res.json();
        })
        .then(data => {
            return data;
        })
    return result;
}

export const fetchData = async (token) => {
    const result = await fetch(`${url}/apps`, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(res => {
            return res.json();
        })
        .then(data => {
            return data;

        })
    return result;
}

export const fetchAppSwitch = async (token, data) => {
    const result = await fetch(`${url}/app/status`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(res => {
            return res.json();
        })
        .then(data => {
            return data;

        })
    return result;
}

export const fetchCreate = async (token, data) => {
    const result = await fetch(`${url}/app/create`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(res => {
            return res.json();
        })
        .then(data => {
            return data;

        })
    return result;
}

export const updateData = async (token, data) => {
    const result = await fetch(`${url}/app/edit`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(res => {
            return res.json();
        })
        .then(data => {
            return data;

        })
    return result;
}

export const deleteData = async (token, data) => {
    const result = await fetch(`${url}/app/delete`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Authorization": `Bearer ${token}`
        }
    })
        .then(res => {
            return res.json();
        })
        .then(data => {
            return data;

        })
    return result;
}
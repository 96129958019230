import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import React from "react";

export default function StyledButton({
  type,
  color,
  size,
  style,
  loading,
  disabled,
  callback,
  children,
}) {
  return (
    <LoadingButton
      variant={type}
      color={color ? color : "primary"}
      size={size}
      loading={loading}
      disabled={Boolean(+disabled)}
      style={{
        textTransform: "capitalize",
        ...style,
      }}
      onClick={callback}
    >
      {children}
    </LoadingButton>
  );
}

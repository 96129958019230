import { Box, TextField, Typography } from "@mui/material";
import { useState } from "react";
import StyledButton from "../Components/StyledButton";
import { fetchCreate } from "../config/api";
import { getTokenValue } from "../config/cookie";
import { Backdrop, Icon } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";

function StyledModal({ open, setOpen, callback, data, setData, loading }) {

  return (
    <Backdrop open={open} style={{ color: "#fff", zIndex: 9999 }}>
      <Box
        style={{
          position: "absolute",
          top: "0px",
          bottom: "0px",
          left: "0px",
          right: "0px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "50px 30px",
            backgroundColor: "#f0f0f0",
            borderRadius: "10px",
            width: "100%",
            maxWidth: "500px",
            position: "relative",
          }}
        >
          <Icon
            style={{
              display: "flex!important",
              width: "fit-content!important",
              height: "fit-content!important",
              cursor: "pointer",
              color: "red",
              position: "absolute",
              right: "10px",
              top: "20px",
            }}
            onClick={() => setOpen(false)}
          >
            <CloseIcon
              style={{
                width: "30px!important",
                height: "30px!important",
              }}
            />
          </Icon>

          <Typography style={{ color: "black", textAlign: "center" }}>
            Create App
          </Typography>

          <TextField
            label="Name"
            variant="standard"
            type="name"
            value={data.name}
            onChange={(e) => setData({ ...data, name: e.target.value })}
            style={{ margin: "15px 0px" }}
          />
          <TextField
            label="Identify"
            variant="standard"
            type="name"
            value={data.identify}
            onChange={(e) => setData({ ...data, identify: e.target.value })}
          />
          <TextField
            label="Play URL"
            variant="standard"
            type="name"
            value={data.play_url}
            onChange={(e) => setData({ ...data, play_url: e.target.value })}
          />
          <TextField
            label="App URL"
            variant="standard"
            type="name"
            value={data.app_url}
            onChange={(e) => setData({ ...data, app_url: e.target.value })}
          />
          <TextField
            label="Description"
            variant="standard"
            type="name"
            value={data.description}
            onChange={(e) => setData({ ...data, description: e.target.value })}
          />

          <StyledButton
            type="contained"
            size="medium"
            loading={loading}
            callback={callback}
            style={{ marginTop: "20px", backgroundColor: "rgb(25, 118, 210)" }}
          >
            Create
          </StyledButton>

        </Box>
      </Box>
    </Backdrop>
  );
}

export default StyledModal;

import { useState } from "react";
import { IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import StyledButton from "./StyledButton";
import EditModal from "./EditModal";
import DeleteModal from "./DeleteModal";

function EditButtons ({width, data, update, deleteData}) {
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const handleEditOpen = () => {
    setEditOpen(true);
    console.log(data);
  }

  return (
    <>
      <div>
        <StyledButton
          type="contained"
          color="primary"
          size="small"
          loading={false}
          callback={handleEditOpen}
          disabled={false}
          style={{
            margin: width !== "xs" ? "0 7px 0 0" : "0 0 7px 0",
          }}
        >
          Edit
        </StyledButton>
        <IconButton aria-label="delete" onClick={() => setDeleteOpen(true)}>
          <DeleteIcon />
        </IconButton>
      </div>

      <EditModal 
        open={editOpen}
        setOpen={setEditOpen}
        data={data}
        update={update}
      />

      <DeleteModal
        open={deleteOpen}
        setOpen={setDeleteOpen}
        id={data.id}
        deleteData={deleteData}
      />
    </>
  );
}

export default EditButtons;
import { useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import StyledButton from "../Components/StyledButton";
import { Backdrop, Icon } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";

function EditModal({ open, setOpen, data, update }) {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(data);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    
    update(formData);

    setLoading(false);
  }

  return (
    <Backdrop open={open} style={{ color: "#fff", zIndex: 9999 }}>
      <Box
        style={{
          position: "absolute",
          top: "0px",
          bottom: "0px",
          left: "0px",
          right: "0px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "50px 30px",
            backgroundColor: "#f0f0f0",
            borderRadius: "10px",
            width: "100%",
            maxWidth: "500px",
            position: "relative",
          }}
        >
          <Icon
            style={{
              display: "flex!important",
              width: "fit-content!important",
              height: "fit-content!important",
              cursor: "pointer",
              color: "red",
              position: "absolute",
              right: "10px",
              top: "20px",
            }}
            onClick={() => setOpen(false)}
          >
            <CloseIcon
              style={{
                width: "30px!important",
                height: "30px!important",
              }}
            />
          </Icon>

          <Typography style={{ color: "black", textAlign: "center" }}>
            Edit App
          </Typography>

          <TextField
            label="Name"
            name="name"
            variant="standard"
            type="name"
            value={formData.name}
            style={{ margin: "15px 0px" }}
            onChange={handleChange}
          />
          <TextField
            label="Identify"
            name="identify"
            variant="standard"
            type="identify"
            value={formData.identify}
            style={{ margin: "15px 0px" }}
            onChange={handleChange}
          />
          <TextField
            label="Description"
            name="description"
            variant="standard"
            type="description"
            value={formData.description}
            style={{ margin: "15px 0px" }}
            onChange={handleChange}
          />
          <TextField
            label="Play URL"
            name="play_url"
            variant="standard"
            type="play_url"
            value={formData.play_url}
            style={{ margin: "15px 0px" }}
            onChange={handleChange}
          />
          <TextField
            label="App URL"
            name="app_url"
            variant="standard"
            type="app_url"
            value={formData.app_url}
            style={{ margin: "15px 0px" }}
            onChange={handleChange}
          />

          <StyledButton
            type="contained"
            size="medium"
            loading={loading}
            callback={handleSubmit}
            style={{ marginTop: "20px", backgroundColor: "rgb(25, 118, 210)" }}
          >
            Edit
          </StyledButton>
        </Box>
      </Box>
    </Backdrop>
  );
}

export default EditModal;

import Cookies from 'js-cookie';

export const getToken = () => {
    return !!Cookies.get('token') && !Cookies.get('token').includes("undefined");
}

export const getTokenValue = () => {
    return Cookies.get('token');
}

export const setToken = (token) => {
    return Cookies.set('token', token, { path: '/', sameSite: 'strict', expires: 360000 });
}

export const unSetToken = () => {
    return Cookies.remove('token');
}